<template>
    <div class="container">
        <div class="row justify-content-md-center">
            <div class="col-sm-5">
                <div class="card border-0">
                    <h2 class="card-header card-title deer-header">
                        My Projects
                    </h2>
                    <div class="card-body deer-body">
                        <p class="card-text">
                            Below, you can find listed the various projects I've worked on. You'll also
                            find descriptions, timeframes, and links to the project repositories where
                            available
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <div class="row justify-content-md-center">
            <div class="card border-0 col-sm-12">
                <img src="../assets/DataImg.jpg" alt="..." class="card-image-top rounded-top project-image">
                <h2 class="card-header card-title deer-header">
                    PROJECT TITLE 1
                </h2>
                <div class="card-body deer-body">
                    <p class="card-text"><small class="text-muted">
                        YEAR CREATED
                    </small></p>
                    <p class="card-text">
                        DESCRIPTION
                    </p>
                    <a href="/" class="btn btn-page-link col-5">
                        LINK TO REPOSITORY
                    </a>
                </div>
            </div>
        </div>
        <br />
        <div class="row justify-content-md-center">
            <div class="card border-0 col-sm-12">
                <img src="../assets/DataImg.jpg" alt="..." class="card-image-top rounded-top project-image">
                <h2 class="card-header card-title deer-header">
                    PROJECT TITLE 2
                </h2>
                <div class="card-body deer-body">
                    <p class="card-text"><small class="text-muted">
                        YEAR CREATED
                    </small></p>
                    <p class="card-text">
                        DESCRIPTION
                    </p>
                    <a href="/" class="btn btn-page-link col-5">
                        LINK TO REPOSITORY
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
}
</script>
