<template>
    <iframe src="https://codesandbox.io/embed/fcksrc?view=editor+%2B+preview&module=%2Fsrc%2FApp.tsx"
     style="width:100%; height: 500px; border:0; border-radius: 4px; overflow:hidden;"
     title="wizardly-lamport"
     allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking"
     sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
   ></iframe>
</template>

<script set>
export default {
}
</script>
