<template>
    <div class="container">
        <div class="row justify-content-md-center">
            <div class="col-sm-5">
                <div class="card border-0">
                    <h2 class="card-header card-title deer-header">
                        About Me
                    </h2>
                    <div class="card-body deer-body">
                        <p class="card-text">
                            I'm just a lil guy
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
}
</script>
